import React, { useEffect, useState } from 'react';
import Table from '../../../../components/table';
import styles from './index.module.scss';
import sharedStyles from '../../shared/index.module.scss';
import { useAdminApi } from '../../../../services/admin-service';
import { authRoles } from '../../../../constants/auth-roles';
import Layout from '../../../../components/layout';
import RecruiterActionButton from '../recruiter-action-button';
import SimplifiedHeader from '../../../../components/simplified-header';
import { useAuth } from '../../../../providers/auth-provider';
import SimplifiedFooter from '../../../../components/simplified-footer';
import CreateAdminAssistantModal from './create-admin-assistant-modal';
import DisableStaffEmailModal from './disable-staff-email-modal';

/* eslint-disable react-hooks/exhaustive-deps */

const RecruiterManagementDashboard = () => {
  const [hasFetchError, setHasFetchError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [recruiters, setRecruiters] = useState([]);
  const [isAdminAssistantModalOpen, setIsAdminAssistantModalOpen] =
    useState(false);
  const [isDisableStaffEmailModalOpen, setIsDisableStaffEmailModalOpen] =
    useState(false);
  const { getRecruiters } = useAdminApi();
  const { getFullName } = useAuth();
  const { firstName, lastName } = getFullName();

  useEffect(() => {
    getRecruiters()
      .then(({ data }) => {
        setRecruiters(data);
      })
      .catch(() => {
        setHasFetchError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const updateRowCallback = (rowIdx, dataKey, newValue) => {
    let recruiterDataToUpdate = [...recruiters];
    recruiterDataToUpdate[rowIdx][dataKey] = newValue;

    setRecruiters(recruiterDataToUpdate);
  };

  return (
    <Layout
      role={authRoles.ADMIN}
      headerComponent={SimplifiedHeader}
      footerComponent={SimplifiedFooter}
      hideContactBanner
    >
      <section className={`section ${sharedStyles.contentSection}`}>
        <div className={`${styles.flex}`}>
          <div className={`${styles.flexFill} `} />
          <h1
            className={`is-2 title has-text-centered ${styles.sectionHeadingText} ${styles.flexFill} ${sharedStyles.sectionHeadingText}`}
          >
            Dashboard - {firstName} {lastName}
          </h1>
          <div
            className={`${styles.flex} ${styles.flexFill} ${styles.adminActionButtons}`}
          >
            <button
              className={`button is-small is-info`}
              onClick={() => setIsAdminAssistantModalOpen(true)}
            >
              Create Admin Assistant
            </button>
            <button
              className={`button is-small is-danger ${styles.marginLeft}`}
              onClick={() => setIsDisableStaffEmailModalOpen(true)}
            >
              Disable Staff Email
            </button>
          </div>
        </div>
        <div className={sharedStyles.contentContainer}>
          <h1 className={`title is-2 ${sharedStyles.sectionHeadingText}`}>
            Manage Recruiters
          </h1>
          <Table
            columns={[
              { heading: 'Name', dataKey: 'fullName' },
              { heading: 'Email', dataKey: 'emailAddress' },
              { heading: 'Status', dataKey: 'status' },
              {
                heading: 'Action',
                dataKey: 'status',
                dataRenderComponent: <RecruiterActionButton />,
              },
            ]}
            rowData={recruiters}
            tableClass={`is-fullwidth ${styles.table}`}
            hasFetchError={hasFetchError}
            updateRowCallback={updateRowCallback}
            isLoading={isLoading}
          />
        </div>
        <CreateAdminAssistantModal
          isOpen={isAdminAssistantModalOpen}
          onModalClose={() => setIsAdminAssistantModalOpen(false)}
        />
        <DisableStaffEmailModal
          isOpen={isDisableStaffEmailModalOpen}
          onModalClose={() => setIsDisableStaffEmailModalOpen(false)}
        />
      </section>
    </Layout>
  );
};

export default RecruiterManagementDashboard;
