import React from 'react';
import { authRoles } from '../../constants/auth-roles';
import RecruiterManagementDashboard from './components/recruiter-management-dashboard';
import { Router } from '@reach/router';
import ProtectedRoute from '../../components/protected-route';

const AdminAppWrapper = () => {
  return (
    <Router basepath="/admin">
      <ProtectedRoute
        path="recruiter-management"
        acceptedRoles={[authRoles.ADMIN]}
        component={RecruiterManagementDashboard}
      />
    </Router>
  );
};

export default AdminAppWrapper;
