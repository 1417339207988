import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useAdminApi } from '../../../../services/admin-service';
import { recruiterStatuses } from '../../../../constants/recruiter-statuses';
import PopupNotification from '../../../../components/popup-notification';
import btnHelper from './recruiter-action-button-helper';
import { errorMessages } from '../../../../constants/error-messages';

const RecruiterActionButton = ({
  rowData,
  dataKey,
  rowIdx,
  updateRowCallback,
}) => {
  const { inviteRecruiter } = useAdminApi();

  const [
    isDisplayingFailureToProcessAlert,
    setIsDisplayingFailureToProcessAlert,
  ] = useState(false);

  function updateRecruiter(targetStatus, rowData) {
    if (targetStatus === recruiterStatuses.INVITE_SENT)
      processInvitation(rowData);
  }

  function processInvitation(rowData) {
    inviteRecruiter(rowData)
      .then(() => {
        updateRowCallback(rowIdx, dataKey, recruiterStatuses.INVITE_SENT);
      })
      .catch(() => {
        setIsDisplayingFailureToProcessAlert(true);
      });
  }

  let recruiterStatus = rowData[dataKey];
  let btnSettings = btnHelper.getBtnSettings(recruiterStatus);

  return (
    <>
      <button
        className={`button is-small ${btnSettings.styleClass}`}
        disabled={btnSettings.disabled}
        onClick={() => {
          updateRecruiter(btnSettings.targetStatus, rowData);
        }}
      >
        {btnSettings.btnText}
      </button>

      {isDisplayingFailureToProcessAlert && (
        <PopupNotification
          message={errorMessages.REQUEST_FAILED}
          duration="medium"
          onClose={() => setIsDisplayingFailureToProcessAlert(false)}
          notificationStyleClass="is-danger"
        />
      )}
    </>
  );
};

RecruiterActionButton.propTypes = {
  rowData: PropTypes.object,
  dataKey: PropTypes.string,
  rowIdx: PropTypes.number,
};

export default RecruiterActionButton;
