import { recruiterStatuses } from '../../../../constants/recruiter-statuses';

/* eslint-disable default-case */

const recruiterActionButtonHelper = {
  getBtnSettings: getBtnSettings,
};

function createBtnSettings(btnText, targetStatus, styleClass, disabled = false) {
  return {
    btnText: btnText,
    targetStatus: targetStatus,
    styleClass: styleClass,
    disabled: disabled
  };
}

function getBtnSettings(recruiterStatusName) {
  let btnSettings;

  switch (recruiterStatusName) {
    case recruiterStatuses.INVITE_SENT:
      btnSettings = createBtnSettings(
        'Invite Again',
        recruiterStatuses.INVITE_SENT,
        'is-info'
      );
      break;
    case recruiterStatuses.NOT_INVITED:
      btnSettings = createBtnSettings(
        'Invite',
        recruiterStatuses.INVITE_SENT,
        'is-info'
      );
      break;
    case recruiterStatuses.REGISTERED:
      btnSettings = createBtnSettings(
        'Enabled',
        null,
        'is-info',
        true
      );
      break;
  }

  return btnSettings;
}

export default recruiterActionButtonHelper;
