import * as Yup from 'yup';

const VALID_EMAIL_MESSAGE = 'Please enter a valid email';

const validationSchema = Yup.object({
  emailAddress: Yup.string()
    .required(VALID_EMAIL_MESSAGE)
    .email(VALID_EMAIL_MESSAGE),
});

export default validationSchema;
