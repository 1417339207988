import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import Modal from '../../../../../components/modal';
import Input from '../../../../common/components/forms/formik-inputs/input';
import { useAdminApi } from '../../../../../services/admin-service';
import validationSchema from './validation-schema';
import { createAdminAssistantStatusMsgs } from './create-admin-assistant-status-msgs';
import { httpResponses } from '../../../../../constants/http-responses';
import { isValidEmail } from '../../../../../utils/validation-utils';
import PopupNotification from '../../../../../components/popup-notification';
import { authRoleIds } from '../../../../../constants/auth-roles';

const CreateAdminAssistantModal = ({ isOpen, onModalClose }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [isErrorMessage, setIsErrorMessage] = useState(false);

  const { createStaffMember } = useAdminApi();

  const showPopupMessage = (message, isErrMsg) => {
    setPopupMessage(message);
    setShowPopup(true);
    setIsErrorMessage(isErrMsg);
  };

  const handleSuccess = resetForm => {
    showPopupMessage(
      createAdminAssistantStatusMsgs.ADMIN_ASSISTANT_CREATED,
      false
    );

    resetForm();
  };

  const handleFailure = err => {
    const isEmailAlreadyInUse =
      err?.response?.status === httpResponses.CONFLICT_ERROR_CODE;

    showPopupMessage(
      isEmailAlreadyInUse
        ? createAdminAssistantStatusMsgs.EMAIL_ALREADY_IN_USE
        : createAdminAssistantStatusMsgs.API_ERROR,
      true
    );
  };

  const onSubmit = (values, { resetForm }) => {
    if (!values.emailAddress || !isValidEmail(values.emailAddress)) {
      showPopupMessage(createAdminAssistantStatusMsgs.INVALID_EMAIL, true);
      return;
    }

    setIsSubmitting(true);
    createStaffMember({
      ...values,
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
    })
      .then(() => handleSuccess(resetForm))
      .catch(handleFailure)
      .finally(() => setIsSubmitting(false));
  };

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        emailAddress: '',
        roleId: authRoleIds.ADMIN_ASSISTANT,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <Modal
          title="Create Admin Assistant"
          message={
            <>
              <Input
                name={'firstName'}
                labelText="First name"
                labelClass="has-text-black"
                errorClass="has-text-danger"
              />
              <Input
                name={'lastName'}
                labelText="Last name"
                labelClass="has-text-black"
                errorClass="has-text-danger"
              />
              <Input
                name={'emailAddress'}
                labelText="Email address"
                labelClass="has-text-black"
                errorClass="has-text-danger"
                autoComplete="email"
              />
              {showPopup && (
                <PopupNotification
                  message={popupMessage}
                  onClose={() => setShowPopup(false)}
                  notificationStyleClass={`${
                    isErrorMessage ? 'is-danger' : 'is-success'
                  }`}
                />
              )}
            </>
          }
          isOpen={isOpen}
          isSubmitting={isSubmitting}
          onCancel={onModalClose}
          shouldSubmitFormOnConfirm={true}
          confirmButtonModifierClass="is-primary"
          showCancelButton={!isSubmitting}
          messageModifierClass={'is-info'}
        />
      </Form>
    </Formik>
  );
};

CreateAdminAssistantModal.prototype = {
  isOpen: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
};

export default CreateAdminAssistantModal;
