import React from 'react';
import AdminAppWrapper from './admin-app-wrapper';
import LoadingSpinner from '../../components/loading-spinner';
import { useAuth } from '../../providers/auth-provider';

const AdminApp = ({ mounted }) => {
  const { hasMetaLoaded } = useAuth();
  if (mounted) {
    return hasMetaLoaded ? (
      <AdminAppWrapper />
    ) : (
      <LoadingSpinner isLoading={true} />
    );
  }
  return null;
};

export default AdminApp;
