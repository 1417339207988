import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import AdminApp from '../../apps/admin';

const Admin = ({ data, location }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <>
      <Helmet>
        <title>Admin - Trustaff</title>
      </Helmet>
      <AdminApp mounted={mounted} />
    </>
  );
};

export default Admin;
