import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import Modal from '../../../../../components/modal';
import Input from '../../../../common/components/forms/formik-inputs/input';
import { useAdminApi } from '../../../../../services/admin-service';
import { disableStaffEmailStatusMsgs } from './disable-staff-email-status-msgs';
import validationSchema from './validation-schema';
import PopupNotification from '../../../../../components/popup-notification';

const DisableStaffEmailModal = ({ isOpen, onModalClose }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [apiError, setApiError] = useState('');

  const { revokeUserAccess } = useAdminApi();

  const showPopupMessage = message => {
    setPopupMessage(message);
    setShowPopup(true);
  };

  const handleSuccess = resetForm => {
    showPopupMessage(disableStaffEmailStatusMsgs.STAFF_MEMBER_DISABLED);
    resetForm();
  };

  const handleFailure = err => {
    const errorMessage =
      err?.response?.data?.errMsg ?? disableStaffEmailStatusMsgs.API_ERROR;
    setApiError(errorMessage);
  };

  const onSubmit = (values, { resetForm }) => {
    setApiError('');
    setIsSubmitting(true);
    revokeUserAccess(values.emailAddress)
      .then(() => handleSuccess(resetForm))
      .catch(handleFailure)
      .finally(() => setIsSubmitting(false));
  };

  return (
    <Formik
      initialValues={{
        emailAddress: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <Modal
          title="Disable Staff Email"
          message={
            <>
              <Input
                name={'emailAddress'}
                labelText="Email address"
                labelClass="has-text-danger"
                errorClass="has-text-danger"
                autoComplete="email"
                placeholder="Enter staff email to be disabled"
              />
              {showPopup && (
                <PopupNotification
                  message={popupMessage}
                  onClose={() => setShowPopup(false)}
                  notificationStyleClass="is-success"
                />
              )}
            </>
          }
          isOpen={isOpen}
          isSubmitting={isSubmitting}
          onCancel={() => {
            setApiError('');
            onModalClose();
          }}
          shouldSubmitFormOnConfirm={true}
          confirmButtonModifierClass="is-danger"
          showCancelButton={!isSubmitting}
          messageModifierClass={'is-danger'}
          hasApiError={!!apiError}
          apiErrorMessage={apiError}
        />
      </Form>
    </Formik>
  );
};

DisableStaffEmailModal.prototype = {
  isOpen: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
};

export default DisableStaffEmailModal;
