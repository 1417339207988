import * as Yup from 'yup';

const VALID_FIRST_NAME = 'Please enter a valid first name';
const VALID_LAST_NAME = 'Please enter a valid last name';
const VALID_EMAIL_MESSAGE = 'Please enter a valid email';

const validationSchema = Yup.object({
  firstName: Yup.string().trim().required(VALID_FIRST_NAME),
  lastName: Yup.string().trim().required(VALID_LAST_NAME),
  emailAddress: Yup.string()
    .required(VALID_EMAIL_MESSAGE)
    .email(VALID_EMAIL_MESSAGE),
});

export default validationSchema;
